import Dashboard from "./views/Dashboard/Dashboard";
import AddEmployee from "./views/AddEmployee/AddEmployee";
import EmployeeList from "./views/AddEmployee/EmployeeList";
import AddBankPartner from "./views/AddBankPartner/AddBankPartner";
import EditBankPartner from "./views/AddBankPartner/EditBankPartner";
import BankPartnerList from "./views/AddBankPartner/BankPartnerList";
import ApplicantsList from "./views/Applicants/ApplicantsList";
import ApplicationRemark from "./views/Applicants/ApplicationRemark";
import ApplicationExport from "./views/ApplicationExport/ApplicationExport";
import ContactUs from "./views/ContactUs/ContactUs";
import Promotor from "./views/Promotor/Promotor";
import ContactRemark from "./views/ContactUs/ContactRemark";
import Query from "./views/Query/Query";
import QueryRemark from "./views/Query/QueryRemark";
import UpdatePassword from "./views/UpdatePassword/UpdatePassword";

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: "/", exact: true, name: "Home" },
  { path: "/dashboard", name: "Dashboard", component: Dashboard },
  { path: "/add_employee", name: "Add Employee", component: AddEmployee },
  { path: "/view_employee", name: "View Employee", component: EmployeeList },
  { path: "/add_bank_partner", name: "Add Bank Partner", component: AddBankPartner },
  { path: "/edit_bank_partner", name: "Edit Bank Partner", component: EditBankPartner },
  { path: "/view_bank_partner", name: "View Bank Partner", component: BankPartnerList },
  { path: "/view_applications", name: "All Applications", component: ApplicantsList },
  { path: "/application_remark", name: "Applicants Remark", component: ApplicationRemark },
  { path: "/application_export", name: "Applications", component: ApplicationExport },
  { path: "/contact_us", name: "Contact Us", component: ContactUs },
  { path: "/promotor", name: "Organization Promotor", component: Promotor },
  { path: "/contact_remark", name: "Contact Remark", component: ContactRemark },
  { path: "/query", name: "Query", component: Query },
  { path: "/query_remark", name: "Query Remark", component: QueryRemark },
  { path: "/updatepassword", name: "Update Password", component: UpdatePassword },
];

export default routes;
