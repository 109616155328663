import Axios from "../utils/axiosInstance";

export const getAllApplication = () => {
  let token = localStorage.getItem("token");
  return Axios.get(`admin/dashboard/all_applicantion`, {
    headers: { "x-auth-token": `${token}` },
  });
};


