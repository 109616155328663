import "./DefaultHeader.css";

import {
  AppAsideToggler,
  AppNavbarBrand,
  AppSidebarToggler,
} from "@coreui/react";
import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  UncontrolledDropdown,
} from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import React, { Component } from "react";

import PropTypes from "prop-types";
import logo from "../../assets/auth/logo-5.jpg";

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {
  render() {
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />

        <img src={logo} width={100} className="ml-lg-3 mr-4" />
        <AppSidebarToggler className="d-md-down-none d-lg-none" display="lg" />

        <Nav className="ml-auto" navbar>
          <UncontrolledDropdown nav direction="down">
            <DropdownToggle nav>
              <img
                src={"../../assets/img/avatars/images (1).png"}
                className="img-avatar"
                alt="admin@bootstrapmaster.com"
              />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem>
                <i className="fa fa-user"></i>
                {localStorage.getItem("first_name") +
                  " " +
                  localStorage.getItem("last_name")}
              </DropdownItem>
              <DropdownItem>
                <Link to="/updatepassword">
                  <i className="fa fa-key"></i> Update Password
                </Link>
              </DropdownItem>
              <DropdownItem onClick={(e) => this.props.onLogout(e)}>
                <i className="fa fa-lock"></i> Logout
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default DefaultHeader;
