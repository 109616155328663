import "./ApplicantsList.css";

import React, { Component } from "react";

import ReactPaginate from "react-paginate";
import Select from "react-select";
import { InputGroup, InputGroupText } from "reactstrap";
import Axios from "../../utils/axiosInstance";
import swal from "sweetalert";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import moment from "moment";
import { getAllApplication } from "../../Service/getAllApplication";
import { getEmployeeList } from "../../Service/getEmployeeList";

export default class ApplicantsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      etp: "3 Days",
      StatusLastUpdate: '20-06-2023',
      ETPLastUpdated: '23-06-2023',
      offset: 0,
      tableData: [],
      exportData: [],
      employeeListData: [],
      employeeList: [],
      statusList: [
        // { value: "PENDING", label: "PENDING" },
        {value:"Application Submitted",label:"APPLICATION SUBMITTED"},
        {value:"Employee Assigned",label:"EMPLOYEE ASSIGNED"},
        { value: "Application In Process", label: "APPLICATION PROCESS" },
        { value: "Bank Assigned", label: "BANK ASSIGNED" },
        { value: "Bank Accepted", label: "BANK ACCEPTED" },
        { value: "All Bank Rejected Application", label: "All BANK REJECTED" },
        { value: "Loan Approved", label: "LOAN APPROVED" },
        { value: "LOAN Disbursed", label: "LOAN DISBURSED" },
        { value: "COMPLETED", label: "COMPLETED" },
        
      ],
      paymentList:[
        {value:"Suggested",label:"SUGGESTED"},
        {value:"Successful",label:"SUCCESSFUL"},
        {value:"Failed",label:"FAILED"},
        
      ],
      orgtableData: [],
      perPage: 10,
      currentPage: 0,
      appname: "",
      empname: "",
      startDate: "",
      endDate: "",
      loader: false,
      selectedEmp: "",
      selectedStatus: "",
      selectedPaymentStatus:"",
      Role: "",
      token: "",
      public_id:""
      
    };
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;
    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.loadMoreData();
      }
    );
  };

 

  clickApplication = async (id) => {
    console.log("Id", id);
    this.props.history.push({
      pathname: `/application_export`,
      state: { public_id: id },
    });
  };

  loadMoreData() {
    const data = this.state.orgtableData;
    const slice = data.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      tableData: slice,
    });
  }

  async getData() {
    let result = await getAllApplication();
    
    
    console.log("Pagination Result", result);
    if (this.state.Role === "ADMIN") {
      if (
        result &&
        result.data &&
        result.data.payload &&
        result.data.payload.applicant &&
        result.data.payload.applicant.length !== 0
      ) {
        
        const tdata = result.data.payload.applicant;
        const slice = tdata.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );
        let tempExportData = result.data.payload.applicant;
        let tempExportObj = tempExportData.map((obj, key) => {
          return {
            sr_no: key + 1,
            applicant_name: `${obj.applicant_first_name} ${obj.applicant_last_name}`,
            employee_name: `${obj.assign_to_first_name} ${obj.assign_to_last_name}`,
            application_status: obj.status,
            application_remark: obj.remark,
            date: obj.created_at,
          };
        });
        this.setState({
          pageCount: Math.ceil(tdata.length / this.state.perPage),
          orgtableData: tdata,
          tableData: slice,
          exportData: tempExportObj,
          loader: false,
        });
      }
    } else {
      if (
        result &&
        result.data &&
        result.data.payload &&
        result.data.payload.length !== 0
      ) {
        const tdata = result.data.payload.applicant;
        
        const slice = tdata.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );
        let tempExportData = result.data.payload.applicant;
        let tempExportObj = tempExportData.map((obj, key) => {
          return {
            sr_no: key + 1,
            applicant_name: `${obj.applicant_first_name} ${obj.applicant_last_name}`,
            application_status: obj.status,
            application_remark: obj.remark,
            date: obj.created_at,
          };
        });
        this.setState({
          pageCount: Math.ceil(tdata.length / this.state.perPage),
          orgtableData: tdata,
          tableData: slice,
          exportData: tempExportObj,
          loader: false,
        });
      }
    }
  }

  componentDidMount() {
    try {
      this.setState({ loader: true });
      this.getData();
      this.loadMoreData();
      let role = localStorage.getItem("role");
      
      this.setState({ Role: role }, async () => {
        console.log(this.state.Role);
        if (this.state.Role === "ADMIN") {
          let employeelist = await getEmployeeList();
          console.log("employeelist", employeelist);
          let tempemployeeList = [],
            tempempList = [];
          employeelist &&
            employeelist.data &&
            employeelist.data.payload.map((obj) => {
              let tempemployeeObj = {
                emp_id: `${obj.public_id}`,
                value: `${obj.first_name} ${obj.last_name}`,
                label: `${obj.first_name} ${obj.last_name}`,
              };
              tempemployeeList.push(tempemployeeObj);
              let tempempObj = {
                fname: `${obj.first_name}`,
                lname: `${obj.last_name}`,
                value: `${obj.first_name} ${obj.last_name}`,
                label: `${obj.first_name} ${obj.last_name}`,
              };
              tempempList.push(tempempObj);
            });
          this.setState({
            employeeListData: tempemployeeList,
            employeeList: tempempList,
            token: localStorage.getItem("token"),
            loader: false,
          });
        } else {
          this.setState({
            token: localStorage.getItem("token"),
            loader: false,
          });
        }
      });
    } catch (error) {
      console.log(error);
      this.setState({ loader: false });
    }
  }

  searchApp = async () => {
    if (document.getElementById("displaytable4").style.display === "none")
      document.getElementById("displaytable4").style.display = "block";
    else document.getElementById("displaytable4").style.display = "block";
    let fullName = this.state.appname.split(" ");
    return await Axios.get(
      `/admin/dashboard/application?applicant_first_name=${fullName[0]}&applicant_last_name=${fullName[1]}`,
      {
        headers: { "x-auth-token": this.state.token },
      }
    )
      .then((result) => {
        console.log("Result", result);
        if (
          result &&
          result.data &&
          result.data.payload &&
          result.data.payload.length !== 0
        ) {
          const tdata = result.data.payload;
          const slice = tdata.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );
          let tempExportData = result.data.payload;
          let tempExportObj = tempExportData.map((obj, key) => {
            return {
              sr_no: key + 1,
              applicant_name: `${obj.applicant_first_name} ${obj.applicant_last_name}`,
              employee_name: `${obj.assign_to_first_name} ${obj.assign_to_last_name}`,
              application_status: obj.status,
              application_remark: obj.remark,
              date: obj.created_at,
            };
          });
          this.setState({
            pageCount: Math.ceil(tdata.length / this.state.perPage),
            orgtableData: tdata,
            tableData: slice,
            exportData: tempExportObj,
            loader: false,
          });
        }
        return Promise.resolve();
      })
      .catch((result) => {
        swal("Failed", "Somthing went wrong", "error");
      });
  };

  searchStartDate = (e) => {
    this.setState(
      { startDate: moment(e.target.value).format("yyyy-MM-DD") },
      () => {
        console.log("startdate", this.state.startDate);
      }
    );
  };

  searchEndDate = (e) => {
    this.setState(
      { endDate: moment(e.target.value).format("yyyy-MM-DD") },
      async () => {
        console.log("enddate", this.state.endDate);
        if (document.getElementById("displaytable4").style.display === "none")
          document.getElementById("displaytable4").style.display = "block";
        else document.getElementById("displaytable4").style.display = "block";
        return await Axios.get(
          `/admin/dashboard/application?start_date=${this.state.startDate}&end_date=${this.state.endDate}`,
          {
            headers: { "x-auth-token": this.state.token },
          }
        )
          .then((result) => {
            console.log("Result", result);
            if (
              result &&
              result.data &&
              result.data.payload &&
              result.data.payload.length !== 0
            ) {
              const tdata = result.data.payload;
              const slice = tdata.slice(
                this.state.offset,
                this.state.offset + this.state.perPage
              );
              let tempExportData = result.data.payload;
              let tempExportObj = tempExportData.map((obj, key) => {
                return {
                  sr_no: key + 1,
                  applicant_name: `${obj.applicant_first_name} ${obj.applicant_last_name}`,
                  employee_name: `${obj.assign_to_first_name} ${obj.assign_to_last_name}`,
                  application_status: obj.status,
                  application_remark: obj.remark,
                  date: obj.created_at,
                };
              });
              this.setState({
                pageCount: Math.ceil(tdata.length / this.state.perPage),
                orgtableData: tdata,
                tableData: slice,
                exportData: tempExportObj,
                loader: false,
              });
            }
            return Promise.resolve();
          })
          .catch((result) => {
            swal("Failed", "Somthing went wrong", "error");
          });
      }
    );
  };

  onClickView = () => {
    window.location.reload();
  };

  

  editETP = async (public_id) => {
    // let public_id = localStorage.getItem("public_id");
    console.log(public_id);
    let token = localStorage.getItem("token");
    // event.preventDefault();
    const etp = prompt("Enter the estimated time period in days:") + " Days";
    if (etp !== null) {
      this.setState({
        etp: etp
      });
      
      localStorage.setItem('etp', etp); // Store etp in local storage
      console.log("ETP:", etp);
      await Axios.put(
        `/admin/dashboard/add_remarks/
      ${public_id}`,
        { est_time: etp },
        {
          headers: {
            "x-auth-token":`${token}` ,
          },
        }
      )
        .then((result) => {
          console.log(
            "Status send successfully",
            result
          );
          swal(
            "Great",
            "Status send successfully",
            "success"
          );
          return Promise.resolve(
            window.location.reload()
          );
        })
        .catch((result) => {
          swal(
            "Failed",
            "Somthing went wrong",
            "error"
          );
        });

      
    }
  };
  

  
  
  
  
  

  render() {
    const { tableData, loader,StatusLastUpdate,ETPLastUpdated,etp} = this.state;
    return (
      <>
        {loader ? (
          <div className="loader"></div>
        ) : (
          <div className="mt-7 container-fluid">
            <div className="row">
              <div className="col">
                <div className="shadow card">
                  <div className="border-0 card-header">
                    <div className="row">
                      <div className="col-lg-3">
                        <InputGroup>
                          <label className="form-control-label">
                            Applicant Name
                          </label>
                        </InputGroup>
                        <InputGroup>
                          <input
                            type="text"
                            className="form-control-alternative form-control"
                            onChange={(e) => {
                              this.setState({ appname: e.target.value });
                            }}
                          />
                          <InputGroupText>
                            <span onClick={this.searchApp}>
                              <i className="icon-magnifier"></i>
                            </span>
                          </InputGroupText>
                        </InputGroup>
                      </div>
                      {this.state.Role === "ADMIN" ? (
                        <div className="col-lg-3">
                          <label className="form-control-label">
                            Employee Name
                          </label>
                          <Select
                            className="selectStyle"
                            options={this.state.employeeList}
                            onChange={async (e) => {
                              if (
                                document.getElementById("displaytable4").style
                                  .display === "none"
                              )
                                document.getElementById(
                                  "displaytable4"
                                ).style.display = "block";
                              else
                                document.getElementById(
                                  "displaytable4"
                                ).style.display = "block";
                              return await Axios.get(
                                `/admin/dashboard/application?assign_to_first_name=${e.fname}&assign_to_last_name=${e.lname}`,
                                {
                                  headers: { "x-auth-token": this.state.token },
                                }
                              )
                                .then((result) => {
                                  console.log("Result", result);
                                  if (
                                    result &&
                                    result.data &&
                                    result.data.payload &&
                                    result.data.payload.length !== 0
                                  ) {
                                    const tdata = result.data.payload;
                                    const slice = tdata.slice(
                                      this.state.offset,
                                      this.state.offset + this.state.perPage
                                    );
                                    let tempExportData = result.data.payload;
                                    let tempExportObj = tempExportData.map(
                                      (obj, key) => {
                                        return {
                                          sr_no: key + 1,
                                          applicant_name: `${obj.applicant_first_name} ${obj.applicant_last_name}`,
                                          employee_name: `${obj.assign_to_first_name} ${obj.assign_to_last_name}`,
                                          application_status: obj.status,
                                          application_remark: obj.remark,
                                          date: obj.created_at,
                                        };
                                      }
                                    );
                                    this.setState({
                                      pageCount: Math.ceil(
                                        tdata.length / this.state.perPage
                                      ),
                                      orgtableData: tdata,
                                      tableData: slice,
                                      exportData: tempExportObj,
                                      loader: false,
                                    });
                                  }
                                  return Promise.resolve();
                                })
                                .catch((result) => {
                                  swal(
                                    "Failed",
                                    "Somthing went wrong",
                                    "error"
                                  );
                                });
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="col-lg-3">
                        <label className="form-control-label">From Date</label>
                        <input
                          type="date"
                          className="form-control-alternative form-control"
                          onChange={this.searchStartDate}
                        />
                      </div>
                      <div className="col-lg-3">
                        <label className="form-control-label">To Date</label>
                        <input
                          type="date"
                          className="form-control-alternative form-control"
                          onChange={this.searchEndDate}
                        />
                      </div>
                    </div>
                    <br />
                    <div className="userList__btn__wrapper">
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{ height: "38px", display: "none" }}
                        id="displaytable4"
                        onClick={this.onClickView}
                      >
                        View All
                      </button>
                      &nbsp;&nbsp;
                      <ReactHTMLTableToExcel
                        className="btn btn-primary newStyle"
                        table="Applications"
                        filename="ApplicationsReport"
                        sheet="Sheet"
                        buttonText={<i className="fa fa-download fa-lg"></i>}
                      />
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="align-items-center table-flush table">
                      <thead className="thead-light">
                        <tr >
                          <th style={{ width: "5%" }}>Sr No</th>
                          <th style={{ width: "10%" }}>Applicant Name</th>
                          {this.state.Role === "ADMIN" ? (
                            <th style={{ width: "16%" }}>Assign Employee</th>
                          ) : (
                            ""
                          )}
                          <th style={{ width: "10%" }}>Status</th>
                          <th style={{ width: "10%" }}>Payment Status</th>
                          {this.state.Role === "EMPLOYEE" ? (
  <>
  <th style={{ width: "8%" }}>Status last Updated</th>
  <th style={{ width: "10%" }}>Estimated time Period</th>
  <th style={{ width: "8%" }}>ETP last updated</th>
  <th style={{ width: "8%" }}>Payment last updated</th>
  
  </>
) : (
  ""
)}
                          
                          <th style={{ width: "7%" }}>Date</th>
                          <th style={{ width: "12%" }} colSpan={1}>
                            Remark
                          </th>
                          <th style={{ width: "1%" }}>View</th>

                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((obj, key) => (
                          <tr key={key}>
                            <td>{key + this.state.offset + 1}</td>
                            <td>
                              {obj.applicant_first_name +
                                " " +
                                obj.applicant_last_name}
                            </td>
                            {this.state.Role === "ADMIN" ? (
                              <td>
                                <Select
                                  options={this.state.employeeListData}
                                  onChange={async (e) => {
                                    console.log("emp id", e.emp_id);
                                    this.setState({ selectedEmp: e.value });
                                    return await Axios.put(
                                      `admin/dashboard/assign_employee/
                                      ${obj.public_id}/${e.emp_id}`,
                                      {},
                                      {
                                        headers: {
                                          "x-auth-token": this.state.token,
                                        },
                                      }
                                    )
                                      .then((result) => {
                                        console.log(
                                          "Assign Employee Result",
                                          result
                                        );
                                        swal(
                                          "Great",
                                          "Employee assigned successfully",
                                          "success"
                                        );
                                        return Promise.resolve(
                                          window.location.reload()
                                        );
                                      })
                                      .catch((result) => {
                                        swal(
                                          "Failed",
                                          "Somthing went wrong",
                                          "error"
                                        );
                                      });
                                  }}
                                  value={
                                    this.state.employeeListData &&
                                    obj.assign_to !== null
                                      ? this.state.employeeListData.find(
                                          (option) =>
                                            option.value ===
                                            obj.assign_to_first_name +
                                              " " +
                                              obj.assign_to_last_name
                                        )
                                      : ""
                                  }
                                />
                              </td>
                            ) : (
                              ""
                            )}
                            <td>
                              <Select
                                options={this.state.statusList}
                                onChange={(e) => {
                                  this.setState(
                                    { selectedStatus: e.value },
                                    async () => {
                                      return await Axios.put(
                                        `/admin/dashboard/add_remarks/
                                      ${obj.public_id}`,
                                        { status: this.state.selectedStatus },
                                        {
                                          headers: {
                                            "x-auth-token": this.state.token,
                                          },
                                        }
                                      )
                                        .then((result) => {
                                          console.log(
                                            "Status send successfully",
                                            result
                                          );
                                          swal(
                                            "Great",
                                            "Status send successfully",
                                            "success"
                                          );
                                          return Promise.resolve(
                                            window.location.reload()
                                          );
                                        })
                                        .catch((result) => {
                                          swal(
                                            "Failed",
                                            "Somthing went wrong",
                                            "error"
                                          );
                                        });
                                    }
                                  );
                                }}
                                value={
                                  this.state.statusList
                                    ? this.state.statusList.find(
                                        (option) => option.value === obj.status
                                      )
                                    : ""
                                }
                              />
                            </td>

                            <td>
                              <Select
                                options={this.state.paymentList}
                                onChange={(e) => {
                                  this.setState(
                                    { selectedPaymentStatus: e.value },
                                    async () => {
                                      return await Axios.put(
                                        `/admin/dashboard/add_remarks/
                                      ${obj.public_id}`,
                                        { paymentStatus: this.state.selectedPaymentStatus },
                                        {
                                          headers: {
                                            "x-auth-token": this.state.token,
                                          },
                                        }
                                      )
                                        .then((result) => {
                                          console.log(
                                            "Status send successfully",
                                            result
                                          );
                                          swal(
                                            "Great",
                                            "Status send successfully",
                                            "success"
                                          );
                                          return Promise.resolve(
                                            window.location.reload()
                                          );
                                        })
                                        .catch((result) => {
                                          swal(
                                            "Failed",
                                            "Somthing went wrong",
                                            "error"
                                          );
                                        });
                                    }
                                  );
                                }}
                                value={
                                  this.state.paymentList
                                    ? this.state.paymentList.find(
                                        (option) => option.value === obj.paymentStatus
                                      )
                                    : ""
                                }
                              />
                            </td>
                           
                            
                            {this.state.Role === "EMPLOYEE" ? (
                              <>
                              <td
                              >
                               {moment(obj.statusUpdateTime ?obj.statusUpdateTime:obj.created_at).format("DD-MM-YYYY hh:mm")}
                              </td>
                              <td
                              onClick={()=> this.editETP(obj.public_id)}
                              >
                             <span className="mr-2">{obj.est_time}</span>
                              <i className="fa fa-edit fa-lg"></i>
                              <span className="tooltip-e-text ml-2">Edit ETP</span>
                              </td>
                              <td>
                              {moment(obj.est_time_update ? obj.est_time_update:obj.created_at).format('DD-MM-YYYY hh:mm')}
                              </td>
                              <td>
                              {moment(obj.paymentStatus ? obj.paymentStatusUpdateTime:obj.created_at).format('DD-MM-YYYY hh:mm')}
                              </td>
                              </>
                            ) : (
                              ""
                            )}
                            <td>
                              {moment(obj.created_at).format("yyyy-MM-DD")}
                            </td>
                            <td

                              onClick={() => {
                                this.props.history.push({
                                  pathname: `/application_remark`,
                                  state: { public_id: obj.public_id },
                                });
                              }}
                            >
                              <span className="mt-1">{obj.remark}</span>
                              <i className="fa fa-edit fa-lg"></i>
                              <span className="tooltip-e-text">Edit Remark</span>
                            </td>
                            <td
                              onClick={() => {
                                this.props.history.push({
                                  pathname: `/application_export`,
                                  state: { public_id: obj.public_id },
                                });
                              }}
                            >
                              <i className="fa fa-eye fa-lg"></i>
                              <span className="tooltip-v-text">View</span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <table
                      className="align-items-center table-flush table"
                      id="Applications"
                      style={{ display: "none" }}
                    >
                      <thead className="thead-light">
                        <tr>
                          <th>Sr No</th>
                          <th>Applicant Name</th>
                          {this.state.Role === "ADMIN" ? (
                            <th>Assign Employee</th>
                          ) : (
                            ""
                          )}
                          <th>Status</th>
                          <th>Remark</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.exportData.map((obj, key) => (
                          <tr key={key}>
                            <td>{obj.sr_no}</td>
                            <td>{obj.applicant_name}</td>
                            {this.state.Role === "ADMIN" ? (
                              <td>{obj.employee_name}</td>
                            ) : (
                              ""
                            )}
                            <td>{obj.application_status}</td>
                            <td>{obj.application_remark}</td>
                            <td>{moment(obj.date).format("yyyy-MM-DD")}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="py-4 card-footer">
                    <ReactPaginate
                      previousLabel={<i className="fa fa-angle-left"></i>}
                      nextLabel={<i className="fa fa-angle-right"></i>}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
