import "./Dashboard.css";

import React, { PureComponent } from "react";


import { getAllApplication } from "../../Service/getAllApplication";

class DashboardEmployee extends PureComponent {
  state = {
    completed_status: 0,
    pending_status: 0,
    total_application: 0,
  };

  async componentDidMount() {
    let allapplicant = await getAllApplication();
    console.log("All applicant", allapplicant);
    this.setState({
      completed_status: allapplicant.data.payload.completed_status,
      pending_status: allapplicant.data.payload.pending_status,
      total_application: allapplicant.data.payload.total_application,
    });
  }
  
  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );
  render() {
    return (
      <>
        <div className="animated fadeIn">
          <div className="header-body">
            <div className="row">
              <div className="col-lg-6 col-xl-4">
                <div className="card-stats mb-4 mb-xl-4 card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col px-2">
                        <h5 className="text-uppercase mb-2 card-title">
                          Total Application
                        </h5>
                        <span className="h2 text-primary mb-0">
                          {this.state.total_application}
                        </span>
                      </div>
                      <div className="col-auto col px-2">
                        <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                          <i className="fa fa-address-book"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-xl-4">
                <div className="card-stats mb-4 mb-xl-4 card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col px-2">
                        <h5 className="text-uppercase mb-2 card-title">
                          Application Completed
                        </h5>
                        <span className="h2 text-primary mb-0">
                          {this.state.completed_status}
                        </span>
                      </div>
                      <div className="col-auto col px-2">
                        <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                          <i className="fa fa-address-book"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-xl-4">
                <div className="card-stats mb-4 mb-xl-4 card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col px-2">
                        <h5 className="text-uppercase mb-2 card-title">
                          Application Pending
                        </h5>
                        <span className="h2 text-primary mb-0">
                          {this.state.pending_status}
                        </span>
                      </div>
                      <div className="col-auto col px-2">
                        <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                          <i className="fa fa-address-book"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>              
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default DashboardEmployee;
